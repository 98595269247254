
import img_1 from '../img/temp/icones/ico (1).png'
import img_2 from '../img/temp/icones/ico (2).png'
import img_3 from '../img/temp/icones/ico (3).png'


const img = [

  {ico_id: 1, src: img_1, link: 'https://www.youtube.com/c/G%C3%A1sSantos',nome:'youtube'},
  {ico_id: 2, src: img_2, link: 'https://www.facebook.com/gassantos3321.1140',nome:'facebook'},
  {ico_id: 3, src: img_3, link: 'https://www.instagram.com/gassantoscaratinga/',nome:'instagram'},
]
export default {
  getIcones() {
    return img
  },
}
