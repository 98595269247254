import {createRouter, createWebHistory} from 'vue-router'


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: '',
      component: () => import(/* webpackChunkName: "Home" */ './Dash.vue'),
      children: [
        {
          path: '/',
          name: 'home',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue'),
          meta: {
            title: 'Home',
            canonical: '/home'
          }
        },
        {
          path: '/produtos/:produto_id',
          name: 'Item',
          component: () => import(/* webpackChunkName: "Produtos" */ './views/home/Home.vue'),
          meta: {
            title: 'Produto',
            canonical: '/produtos/:produto_id'
          }
        },
        {
          path: '/produtos',
          name: 'produtos',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue'),
          meta: {
            title: 'Produtos',
            canonical: '/produtos'
          }
        },
        {
          path: '/cidades',
          name: 'cidades',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue'),
          meta: {
            title: 'Cidades',
            canonical: '/cidades'
          }
        },
        {
          path: '/onde_estamos',
          name: 'onde_estamos',
          component: () => import(/* webpackChunkName: "Home" */ './views/home/Home.vue'),
          meta: {
            title: 'Onde Estamos',
            canonical: '/onde_estamos'
          }
        },
        {
          path: '/empresa',
          name: 'empresa',
          component: () => import(/* webpackChunkName: "Empresa" */ './views/home/Home.vue'),
          meta: {
            title: 'Empresa',
            canonical: '/empresa'
          }
        },
        {
          path: '/sobre',
          name: 'Sobre',
          component: () => import(/* webpackChunkName: "Empresa" */ './views/empresa/Empresa.vue'),
          meta: {
            title: 'sobre',
            canonical: '/sobre'
          }
        },
        {
          path: '/:pathMatch(.*)*',
          name: 'not-found',
          component: () => import(/* webpackChunkName: "404" */ './views/NotFound/NotFound.vue'),
          meta: {
            title: 'Pagina Não Encontrada',
            canonical: '/not-found'
          }
        }
      ]
    }
  ]
});


router.beforeEach((to, from, next) => {
  document.title = to.meta && to.meta.title ? to.meta.title + ' - Gas Santos' : 'Gas Santos'
  next()
})

router.afterEach((to) => {
  const linkCanonical = document.querySelector("link[rel='canonical']")
  const canonicalUrl = window.location.origin + (window.location.pathname || to.meta?.canonical)


  if (linkCanonical) {
    linkCanonical.setAttribute('href', canonicalUrl)
  } else {
    const link = document.createElement('link')
    link.setAttribute('rel', 'canonical')
    link.setAttribute('href', canonicalUrl)
    document.head.appendChild(link)
  }
})

export default router
