<template>
	 <v-footer theme="dark" class="tommy_regular" absolute style="padding: 0px">
			<v-card class="flex" flat tile style="background: transparent; border-radius: 0px; width: 100%">
				 <v-card-text style="background: #00329a">
						<h3 class="txt_branco">DISTRIBUIDORA DE GAS SANTOS LTDA - RUA JUCA VASCONCELOS 282 - SANTA CRUZ -
							 CARATINGA - MG</h3>
				 </v-card-text>
				 <v-card-text class=" fundoFooter">
						<v-container>
							 <v-row justify="center">
									<v-col cols="12" sm="12" md="4" lg="4">
										 <h2 class="txt_branco" style="font-weight: bold">Nossa Missão</h2>
										 <br>
										 <p style="text-align: justify" class="txt_branco">
												"Oferecer soluções para os clientes que buscam produtos e serviços referentes à Gás e Água
												mineral,
												buscando a satisfação dos nossos colaboradores e clientes, sempre buscando o bem estar da
												comunidade."
										 </p>
										 <div style="text-align: left">
												<v-btn variant="text" to="/sobre" @click="scrollToSection('empresa_pg')" block
															 style="background: rgba(255,255,255,0.04)">Saiba +
												</v-btn>
										 </div>
									</v-col>
									<v-col cols="12" sm="4" md="4" lg="4" style="text-align: center">
										 <div style="text-align: center ">
												<h2 class="txt_branco" style="font-weight: bold">Setores</h2>
												<br>
												<v-row justify="center">
													 <v-col cols="12" sm="10" md="6" lg="6">
															<div>
																 <ul style="text-align: left; cursor: pointer" class="txt_branco">
																		<li @click="irPara('empresa','center')">Empresa</li>
																		<li @click="irPara('produtos')">Produtos e seviços</li>
																		<li @click="irPara('cidades','center')">Cidades</li>
																		<li @click="irPara('onde_estamos','center')">Onde Estamos</li>
																 </ul>
															</div>
													 </v-col>
													 <!--REDES SOCIAIS-->
													 <v-col cols="12">
															<h2 class="txt_branco" style="font-weight: bold">Redes Sociais</h2>
															<br>
															<v-item-group selected-class="bg-primary">
																 <v-row justify="center">
																		<v-col v-for="(img,index) in icones.getIcones()"
																					 cols="3" sm="3" md="3" lg="2">
																			 <v-item v-slot="{ isSelected, selectedClass }">
																					<v-card elevation="0" style="background: transparent" height="30"
																									@click="IrParaUrl(img.link)">
																						 <v-img :src="img.src" style="height: 30px; width: 100%" contain></v-img>
																					</v-card>
																			 </v-item>
																		</v-col>
																 </v-row>
															</v-item-group>
													 </v-col>
												</v-row>
										 </div>
									</v-col>
									<v-col cols="12" sm="4" md="4" lg="4">

										 <!--SITE SEGURO-->
										 <site-seguro classe="txt_branco" url="https://gassantos.com.br"></site-seguro>

										 <v-row>
												<!--FORMAS DE PAGAMENTO-->
												<v-col cols="12">
													 <h2 class="txt_branco" style="font-weight: bold"> Formas de pagamento</h2>
													 <br>
													 <v-row justify="center">
															<v-col cols="12">
																 <v-img :src="bandeira" style="height: 50px; width: 100%" contain></v-img>
															</v-col>
													 </v-row>
												</v-col>
										 </v-row>

									</v-col>
							 </v-row>
						</v-container>
				 </v-card-text>
				 <v-card-text class="py-2 white--text text-center gradiente_vermelho">
						<span>Copyrigth &ensp; &copy;{{ new Date().getFullYear() }} —  <a href="http://www.prodatanet.com.br/"
																																							style="color: white; text-decoration: none"><b>Prodata Informática</b></a>. Todos os direitos reservados.</span>
				 </v-card-text>
			</v-card>
	 </v-footer>
</template>

<script>
  import icones from '../assets/js/img_icones'
  import bandeira from '../assets/img/bandeiras.webp'
  import miscMixin from '../helpers/misc'
  import SiteSeguro from './SiteSeguro/SiteSeguro'

  export default {
    name: 'Rodape',
    mixins: [miscMixin],
    data: () => ({
      bandeira,
      icones
    }),
    components: {
      SiteSeguro
    },
  }
</script>
